.actions {
   width: 100%;

   .action {
      width: calc(100% - 2rem);
      padding: 1rem 0.75rem;
      margin: 1rem;

      font-size: 1.25rem;

      border-style: solid;
      border-radius: 0.375rem;

      border-color: var(--clr-danger);
      background-color: var(--clr-danger);
      color: var(--on-danger);

      cursor: pointer;

      &:hover {
         border-color: var(--clr-danger-dark);
         background-color: var(--clr-danger-dark);
      }
   }
}
