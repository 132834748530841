.backdrop {
   display: none;
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   background: rgba(0, 0, 0, 0.5);
   z-index: 9999;

   &[data-shown='true'] {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }

   .modal {
      background-color: var(--clr-bg);
      color: var(--on-bg);
      padding: 1rem;
      border-radius: 0.5rem;
      border-style: solid;

      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      .header {
         display: flex;
         flex-direction: row;
         justify-content: space-between;
         align-items: center;
         gap: 2rem;

         width: 100%;

         padding: 1rem;

         .heading {
            font-size: 2rem;
            font-weight: 700;
            margin: 0;
         }

         .button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            border: none;
            background-color: transparent;

            color: var(--on-bg);

            cursor: pointer;
         }
      }

      .content {
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         width: 100%;
      }
   }
}
